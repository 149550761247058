<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-label>
      <div class="h6">
        {{ formatDate(inventory_count.date, "MMMM do, yyyy") }}
      </div>

    </ion-label>

    <ion-label slot="end" class="ion-text-right ion-no-margin">
      <div class="p">{{ preface }}{{ inventory_count.count }}</div>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";
import { formatDate } from "@/util/helpers";

export default {
  props: {
    inventory_count: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonLabel,
  },

  computed: {
    preface() {
      if (this.inventory_count.type === "balance")
        return "Balance of "
      else {
        if (this.inventory_count.count >= 0)
          return "Added +"
        else
          return "Removed "
      }
    }
  },

  data() {
    return {
      formatDate
    }
  }
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>