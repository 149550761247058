<template>
  <base-layout-two page-title="Inventory" page-default-back-link="/inventories">
    <section class="ion-padding background-white">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col>
            <h1>{{ inventory.name }} ({{ count }} {{ inventory.inventory_units }})</h1>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <base-field-display label-text="Category">
              <p>{{ inventory.category }}</p>
            </base-field-display>
          </ion-col>
          <ion-col size="6">
            <base-field-display label-text="Subcategory">
              <p>{{ inventory.subcategory }}</p>
            </base-field-display>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <div class="padding-8">
      <base-card title="Count History">
        <ion-button class="regular-button" expand="block" :router-link="`/inventories/${$route.params.id}/inventory_counts/create`">
          Add Count
        </ion-button>
        <ion-list>
          <inventory-count-list-item v-for="inventory_count in inventory.inventory_counts" :key="inventory_count.id"
            :inventory_count="inventory_count" :router-link="`/inventories/${$route.params.id}/inventory_counts/${inventory_count.id}`"></inventory-count-list-item>
        </ion-list>
      </base-card>

    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonList, IonButton } from "@ionic/vue";
import InventoryCountListItem from "@/components/inventories/InventoryCountListItem.vue";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonButton,
    InventoryCountListItem
  },

  data() {
    return {
      inventory: {
        inventory_counts: [],
      },
    };
  },

  computed: {
    count() {
      // Get the latest manual balance if it exists
      if (this.inventory.inventory_counts.length > 0 &&
        this.inventory.inventory_counts[0].type === "balance") {
        return this.inventory.inventory_counts[0].count
      }
      // Add the latest manual balance with the last additions and subtractions
      else {
        let indexOfNextBalance = this.inventory.inventory_counts.findIndex((a) => a.type === "balance")

        let count = this.inventory.inventory_counts.slice(0, indexOfNextBalance + 1).reduce(
          (partial_sum, a) => partial_sum + a.count,
          0
        );
        return count.toLocaleString("en-US")
      }
    }
  },

  async ionViewWillEnter() {
    await this.fetchInventory();
  },

  methods: {
    async fetchInventory() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/inventories/${this.$route.params.id}`)
        .then((response) => {
          this.inventory = response.data.inventory;

          this.inventory.inventory_counts.sort((a, b) => {
            return (
              new Date(b.date) - new Date(a.date) || b.id - a.id
            );
          })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>